<template>
  <Layout>
    <PageHeader :items="items" :title="title" />

    <div class="alertMsg">
      <!--      <b-alert v-model="showDismissibleAlert" dismissible variant="success">-->
      <!--        Template Created Succesfully!-->
      <!--      </b-alert>-->
      <!--      <b-alert-->
      <!--        v-model="showDismissibleQuestAlert"-->
      <!--        dismissible-->
      <!--        variant="success"-->
      <!--      >-->
      <!--        Question Deleted Successfully!-->
      <!--      </b-alert>-->
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :disabled="isEdit"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities"
              ></multiselect>
            </div>
            <div class="col-md-2">
              <label>Select City </label>
              <multiselect
                v-model="city"
                :disabled="isEdit"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getPrincipleAndCorporates()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Select Principle Branch </label>
              <multiselect
                v-model="principleBranchID"
                :disabled="isEdit"
                :options="principleBranches"
                :show-labels="false"
                label="principleBranchName"
                track-by="principleBranchID"
                @input="getRestaurantByPrincipleBranch"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="form-label">Select Corporate Branch </label>
              <multiselect
                v-model="corpBranchID"
                :disabled="isEdit"
                :options="corpBranches"
                :show-labels="false"
                label="corpBranchName"
                track-by="corpBranchID"
                @input="getRestaurantByCorporateBranch"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
          </div>
          <div class="row mt-2 mb-3">
            <div class="col-md-4">
              <label class="form-label" for="form row-endTime-input"
                >Select Restaurant Branch*</label
              >
              <multiselect
                v-model="restBranchID"
                :class="{
                  'is-invalid': submitted && $v.restBranchID.$error
                }"
                :disabled="isEdit"
                :options="restBranchArr"
                label="restaurantName"
                track-by="restBranchID"
                @input="
                  getFdbacktemplatebyrestbranch(restBranchID.restBranchID)
                "
              ></multiselect>
              <div
                v-if="submitted && $v.restBranchID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.restBranchID.required"
                  >This value is required.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6 v-if="!isEdit">Create Feedback Reason Group</h6>
          <h6 v-else>Edit Feedback Reason Group</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Reason Group Name</label>
                <input
                  v-model="groupTitle"
                  class="form-control"
                  placeholder="Enter reason group name"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-3">
              <label class="form-label">Status *</label>
              <multiselect
                v-model="group_status"
                :options="statusArr"
                :show-labels="false"
                label="key"
                track-by="val"
              ></multiselect>
            </div>
          </div>

          <div class="inner-repeater mb-4 mt-3">
            <div class="inner mb-3">
              <div
                v-for="(data, index) in taskArr"
                :key="data.id"
                class="inner mb-3 row"
              >
                <div class="col-md-4">
                  <label class="form-label">Reason Title : *</label>
                  <input
                    v-model="data.reason_title"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label class="form-label">Status *</label>
                  <multiselect
                    v-model="data.status"
                    :options="statusArr"
                    :show-labels="false"
                    label="key"
                    track-by="key"
                  ></multiselect>
                </div>
                <div
                  class="col-lg-2 align-self-center d-grid"
                  style="top: 10px;"
                >
                  <input
                    class="btn btn-themeBrown btn-block inner"
                    type="button"
                    value="Delete"
                    @click="deleteItem(index, data.reportTaskID)"
                  />
                </div>
              </div>
            </div>
            <input
              class="btn btn-themeOrange inner"
              type="button"
              value="Add new item"
              @click="AddItem"
            />
          </div>

          <div class="row" style="float:right;">
            <b-spinner
              v-if="loading"
              class="m-2 col-3"
              role="status"
              variant="primary"
            ></b-spinner>
            <button
              v-if="!isEdit"
              :disabled="loading"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="submitForm()"
            >
              Submit
            </button>
            <button
              v-if="isEdit"
              :disabled="loading"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="updateForm()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Feedback Reason Group",
    meta: [
      {
        name: "Feedback Reason Group",
        content: appConfig.description
      }
    ]
  },

  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Add Feedback Reason Group",
      items: [
        {
          text: "Add Feedback Reason Groups",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      taskArr: [
        {
          id: 1,
          reportTaskID: 0,
          reason_title: "",
          status: ""
        }
      ],
      country: "",
      countryArr: [],
      city: "",
      cityArr: [],
      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      restBranchArr: [],
      restBranchID: "",
      statusArr: [
        { key: "Active", val: 1 },
        { key: "Idle", val: 0 }
      ],
      group_status: "",
      groupTitle: "",
      loading: false,
      isEdit: 0,
      req_for_edit: 0
    };
  },
  mounted() {
    this.getAllCountry();
  },
  methods: {
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getAllCities() {
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
        });
    },
    getPrincipleAndCorporates() {
      this.getPrincipleBranches();
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/get-corporate-branches", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.corpBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },

    getRestaurantByPrincipleBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/principle/restaurants", {
          city: this.city ? this.city.city : "",
          principleBranchID: this.principleBranchID
            ? this.principleBranchID.principleBranchID
            : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantByCorporateBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/corporate/restaurants", {
          city: this.city ? this.city.city : "",
          corpBranchID: this.corpBranchID ? this.corpBranchID.corpBranchID : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },

    AddItem() {
      this.taskArr.push({
        reportTaskID: 0,
        reason_title: "",
        status: ""
      });
    },
    deleteItem(index, reportTaskID) {
      if (reportTaskID > 0) {
        if (
          confirm(
            "This will remove this reason permanently. Are you sure you want to delete this reason?"
          )
        ) {
          this.axios
            .post(this.$loggedRole+"/feedback-template/remove-reason", {
              id: reportTaskID
            })
            .then(response => {
              this.taskArr.splice(index, 1);
              alert(response.data.message);
              this.getReasonGroupDetail();
            })
            .catch(error => {
              alert(error.response.data.message);
            });
        }
      } else {
        this.taskArr.splice(index, 1);
      }
    },
    submitForm() {
      if (this.principleBranchID.length <= 0 && this.corpBranchID.length <= 0) {
        alert("Please select either principle branch or corporate branch.");
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        alert(
          "You can create reason groups for either principle branch or corporate branch."
        );
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("title", this.groupTitle);
        formData.append(
          "corporate_branch_id",
          this.corpBranchID ? this.corpBranchID.corpBranchID : ""
        );
        formData.append(
          "principle_branch_id",
          this.principleBranchID ? this.principleBranchID.principleBranchID : ""
        );
        formData.append("restaurant_branch_id", this.restBranchID.restBranchID);
        formData.append("reasons", JSON.stringify(this.taskArr));
        formData.append("status", this.group_status.val);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);

        this.axios
          .post(this.$loggedRole+"/feedback-template/save-reason-group", formData)
          .then(result => {
            this.loading = false;
            alert(result.data.message);
            this.clearForm();
          })
          .catch(error => {
            this.loading = false;
            alert(error.response.data.message);
          });
      }
    },
    clearForm() {
      this.groupTitle = "";
      this.taskArr = [
        {
          id: 1,
          reportTaskID: 0,
          reason_title: "",
          status: ""
        }
      ];
      this.group_status = "";
    },
    getReasonGroupDetail() {
      this.axios
        .get(this.$loggedRole+"/feedback-template/get-reason-group-detail/" + this.req_for_edit)
        .then(response => {
          this.groupTitle = response.data.data.title;
          if (response.data.data.status == 1) {
            this.group_status = { key: "Active", val: 1 };
          }
          if (response.data.data.status == 0) {
            this.group_status = { key: "Idle", val: 0 };
          }
          if (response.data.questions.length > 0) {
            this.taskArr = response.data.questions;
          }
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    updateForm() {
      this.loading = true;
      let formData = new FormData();
      formData.append("id", this.req_for_edit);
      formData.append("title", this.groupTitle);
      formData.append("reasons", JSON.stringify(this.taskArr));
      formData.append("status", this.group_status.val);
      formData.append("createdBy", this.$storageData.profile.pid);
      formData.append("loginTypeID", this.$storageData.login_type);

      this.axios
        .post(this.$loggedRole+"/feedback-template/update-reason-group", formData)
        .then(result => {
          this.loading = false;
          alert(result.data.message);
        })
        .catch(error => {
          this.loading = false;
          alert(error.response.data.message);
        });
    }
  },
  created() {
    if (this.$route.params.type == "edit") {
      this.isEdit = 1;
      this.title = "Edit Feedback Reason Group";
      this.req_for_edit = this.$route.params.id;
      this.getReasonGroupDetail();
    } else {
      this.isEdit = 0;
    }
  },
  middleware: "authentication"
};
</script>

<style scoped></style>
